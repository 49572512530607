<template>
  <div id="main">
    <div class="header__container">
      <div class="pre-page__button"><el-button @click="goBack"><i class="el-icon-arrow-left"></i></el-button></div>
      <p class="heaader-title">쿠폰 등록</p>
    </div>
    <div class="coupon-content__wrap">
      <div class="top-title__line">
        <p>등록하고자 하시는 쿠폰의 번호를 입력해주세요.</p>
      </div>
      <div class="content__line">
        <div class="content__box">
          <el-input type="text" v-model="couponCode" placeholder="쿠폰코드 8자리를 입력해주세요."></el-input>
        </div>
        <div class="notice">
          <p>* 등록된 쿠폰은 해당 계정에 등록되어 1회만 사용가능합니다.</p>
        </div>
      </div>
    </div>
    <div class="footer__container">
      <el-button class="footer__line">등록하기</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'couponIndex',
  components: {},
  data() {
    return {
      couponCode: '',
    };
  },
};
</script>
